import { render, staticRenderFns } from "./MyBatchDetails.vue?vue&type=template&id=0a7bfa32&scoped=true&lang=html&"
import script from "./MyBatchDetails.vue?vue&type=script&lang=js&"
export * from "./MyBatchDetails.vue?vue&type=script&lang=js&"
import style0 from "./MyBatchDetails.vue?vue&type=style&index=0&id=0a7bfa32&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a7bfa32",
  null
  
)

export default component.exports