<template lang="html">
    <section class="wrapper wrapper--xs">
        <div class="my-orders-details">
            <loading-overlay v-if="dataLoading" />
            <div v-if="!dataLoading">
                <billing-card
                    :mode="'solo'"
                    :index="1"
                    :data="batch"
                    @actionDeleted="onActionDeleted"
                />
            </div>
        </div>
    </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import {
    RESET
} from '@/store/modules/order/mutation-types'

import { eventBus } from '@/helpers/event-bus'
import Api from '@/helpers/api/index.js'

import filtersMixin from '@/mixins/filtersMixin.js'

// Components
import BillingCard from '@/components/account/Cards/BillingCard/BillingCard.vue'

const {
    mapMutations: mapOrderMutations
} = createNamespacedHelpers('order')

export default {
    components: {
        BillingCard
    },
    metaInfo() {
        return {
            title: `Billing Details | ${this.batchId}`
        }
    },
    mixins: [
        filtersMixin
    ],
    data: () => ({
        dataLoading: true,
        batch: {}
    }),
    computed: {
        batchId() {
            return this.$route.params.id
        }
    },
    async created() {
        await this.getBatchData()
    },
    methods: {
        ...mapOrderMutations({
            resetOrder: RESET
        }),
        async getBatchData() {
            try {
                this.dataLoading = true
                const { data } = await Api.get('/api/cp/batch/list', {
                    id: this.batchId,
                    per_page: 1,
                    sort_order: 'ASC',
                    sort_by: 'created_at'
                })
                const [batch] = data.data
                this.batch = batch
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.dataLoading = false
            }
        },
        async onActionDeleted(id) {
            const modalTitle = 'Delete the batch'
            const modalText = 'Are you sure to delete the batch?'
            if (await this.$root.$confirm(modalTitle, modalText, { color: 'red' })) {
                try {
                    await Api.delete('/api/cp/batch/delete-unpaid', {
                        batchid: id
                    })
                    eventBus.$emit('showSnackBar', `Batch ${id} has been deleted!`, 'success');
                    this.batches = this.batches.filter((item) => item.batchid !== id)
                } catch (error) {
                    console.error(error);
                    eventBus.$emit('showSnackBar', error, 'error');
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.order .tab-wrapper .tab {
    display: inline;
}

.help-center {
    .tickets {
        padding: 0;
        margin-top: 30px;
    }

    .orders-details-tabs__support-tickets {
        padding: 30px 25px 10px;
        padding-top: 0;
    }

    .support-form {
        margin: 0;
        box-shadow: none;
    }
}
</style>
